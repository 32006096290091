<script setup>

import {computed, inject, ref, watch} from "vue";
import {Head, router, useForm, usePage} from "@inertiajs/vue3";
import {Companies} from "@/Helper/exclusion-helper.js";
import {isAdministrator} from "@/Helper/authorization-helper.js";

const props = defineProps({
    project: Object
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const uploadImportDialog = ref(false)
const preImportDialog = ref(false)
const preImportData = ref()
const preImportForm = useForm({
    file: null,
    start_row: 4,
    matching: [
        undefined,
        'created',
        'company',
        'contact',
        undefined,
        'email',
        'phone',
        'when_date',
        'when_time',
        'info',
    ],
    task_list_id: props.project.lists[0].id
})

const isMyTasksSelected = ref(route().params.myTasks === 'true')
watch(isMyTasksSelected, newIsMyTasksSelected => {
    router.reload({
        data: {
            'myTasks': newIsMyTasksSelected
        }
    })
})

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {
    title: props.project.name,
    back: route('projects')
})

function getImportColumnItemsForColumn() {
    return [
        {
            title: 'Nicht importieren',
            value: null
        },
        {
            title: 'Erstellt am',
            value: 'created',
            props: {
                disabled: preImportForm.matching.includes('created'),
            }
        },
        {
            title: 'Firma',
            value: 'company',
            props: {
                disabled: preImportForm.matching.includes('company'),
            }
        },
        {
            title: 'Ansprechpartner',
            value: 'contact',
            props: {
                disabled: preImportForm.matching.includes('contact'),
            }
        },
        {
            title: 'E-Mail',
            value: 'email',
            props: {
                disabled: preImportForm.matching.includes('email'),
            }
        },
        {
            title: 'Telefon',
            value: 'phone',
            props: {
                disabled: preImportForm.matching.includes('phone'),
            }
        },
        {
            title: 'Wann Datum',
            value: 'when_date',
            props: {
                disabled: preImportForm.matching.includes('when_date'),
            }
        },
        {
            title: 'Wann Zeit',
            value: 'when_time',
            props: {
                disabled: preImportForm.matching.includes('when_time'),
            }
        },
        {
            title: 'Info',
            value: 'info',
            props: {
                disabled: preImportForm.matching.includes('info'),
            }
        },
    ]
}

function uploadImportFile() {
    preImportForm.processing = true

    const formData = new FormData();
    formData.append('file', preImportForm.file);
    formData.append('start_row', preImportForm.start_row);

    axios.post(
        route('projects.details.tasks.pre-import', {project: props.project.id}),
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    ).then(res => {
        preImportData.value = res.data
        preImportDialog.value = true

        uploadImportDialog.value = false
    }).finally(() => preImportForm.processing = false)
}

function importFile() {
    preImportForm.processing = true

    const formData = new FormData();
    formData.append('file', preImportForm.file);
    formData.append('start_row', preImportForm.start_row);
    formData.append('matching', preImportForm.matching);
    formData.append('task_list_id', preImportForm.task_list_id);

    axios.post(
        route('projects.details.tasks.import', {project: props.project.id}),
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    ).then(res => {
        preImportDialog.value = false
        preImportForm.reset()

        router.reload()
    }).finally(() => preImportForm.processing = false)
}
</script>

<template>
    <Head :title="`${props.project.name} - Projekte`"/>

    <v-container
        :fluid="true"
        class="d-flex flex-column ga-3 h-100"
    >
        <div
            class="d-flex align-center"
        >
            <v-tabs
                v-if="![Companies.VALUEVNTRS, Companies.JOINY_DEV].includes(user.company.id) || isAdministrator(user)"
                :model-value="route().current()"
                centered
                color="grey-darken-2"
            >
                <v-tab
                    class="text-none"
                    text="Details"
                    prepend-icon="mdi-details"
                    value="projects.details"
                    @click="router.visit(route('projects.details', project.id), {
                        data: { myTasks: route().params.myTasks }
                    })"
                />

                <v-tab
                    v-if="![Companies.VALUEVNTRS, Companies.JOINY_DEV].includes(user.company.id)"
                    class="text-none"
                    text="Aufgaben"
                    prepend-icon="mdi-file-check-outline"
                    value="projects.details.tasks"
                    @click="router.visit(route('projects.details.tasks', project.id), {
                        data: { myTasks: route().params.myTasks }
                    })"
                />

                <v-tab
                    class="text-none"
                    text="Board"
                    prepend-icon="mdi-view-column-outline"
                    value="projects.details.board"
                    @click="router.visit(route('projects.details.board', project.id), {
                        data: { myTasks: route().params.myTasks }
                    })"
                />
            </v-tabs>
            <v-spacer/>
            <div class="d-flex ga-2">
                <v-tooltip
                    v-if="user.tenant !== 'portal' && [Companies.VALUEVNTRS, Companies.JOINY_DEV, Companies.JOINY].includes(user.company.id)"
                    text="Aufgaben importieren"
                    location="bottom"
                >
                    <template #activator="{ props }">
                        <v-btn
                            icon="mdi-calendar-import-outline"
                            v-bind="props"
                            @click="uploadImportDialog = true"
                        />
                    </template>
                </v-tooltip>

                <v-tooltip
                    text="Meine Aufgaben"
                    location="bottom"
                >
                    <template #activator="{ props }">
                        <v-btn
                            icon="mdi-account-star-outline"
                            :color="isMyTasksSelected ? 'primary' : 'inherit'"
                            v-bind="props"
                            @click="isMyTasksSelected = !isMyTasksSelected"
                        />
                    </template>
                </v-tooltip>
            </div>
        </div>

        <slot/>

        <!-- Upload Import Dialog -->
        <v-dialog
            v-model="uploadImportDialog"
            width="450"
            persistent
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Tasks hochladen</span>
                </v-card-title>
                <v-card-text>
                    <v-file-input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        variant="outlined"
                        label="Excel-Datei"
                        @change="preImportForm.file = $event.target.files[0]"
                        @click:clear="() => preImportForm.file = undefined"
                    />

                    <v-number-input
                        v-model="preImportForm.start_row"
                        variant="outlined"
                        label="Erste Zeile"
                        :min="1"
                    />

                    <v-select
                        v-model="preImportForm.task_list_id"
                        variant="outlined"
                        label="Board-Liste"
                        :items="project.lists"
                        item-title="name"
                        item-value="id"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        :disabled="preImportForm.processing"
                        @click="uploadImportDialog = false; preImportForm.reset()"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="primary"
                        :disabled="!preImportForm.file || !preImportForm.task_list_id"
                        :loading="preImportForm.processing"
                        @click="uploadImportFile"
                    >
                        Hochladen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Pre Import Dialog -->
        <v-dialog
            v-model="preImportDialog"
            width="auto"
            persistent
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Tasks importieren</span>
                </v-card-title>
                <v-card-text>
                    <v-table
                        fixed-header
                    >
                        <thead>
                        <tr>
                            <th></th>
                            <th
                                v-for="columnNumber in preImportData[0].length"
                            >
                                <v-select
                                    v-model="preImportForm.matching[columnNumber - 1]"
                                    :items="getImportColumnItemsForColumn()"
                                    variant="plain"
                                    width="160"
                                />
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(dataRow, index) in preImportData"
                        >
                            <td>{{index + 1}}</td>
                            <td
                                v-for="dataRowColumn in dataRow"
                            >
                                {{ dataRowColumn }}
                            </td>
                        </tr>
                        <tr>
                            <td>...</td>
                        </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        :disabled="preImportForm.processing"
                        @click="preImportDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="primary"
                        type="submit"
                        :loading="preImportForm.processing"
                        :disabled="!preImportForm.matching.includes('company')"
                        @click="importFile"
                    >
                        Importieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
