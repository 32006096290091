<script setup>
import {Head, Link, router, useForm, usePage} from '@inertiajs/vue3';
import Default from "@/Layouts/Default.vue";
import {computed, inject, onMounted, ref} from "vue";
import FileIcon from "@/Components/FileIcon.vue";
import FormProgress from "@/Components/FormProgress.vue";
import MessageModal from "@/Components/MessageModal.vue";
import DeleteFilesDialog from "@/Components/Files/DeleteFilesDialog.vue";
import {openInNewTab} from "@/helper.js";
import {useToast} from "vue-toastification";
import {isAdministrator} from "@/Helper/authorization-helper.js";

defineOptions({layout: Default})
const props = defineProps({
    folder: Object,
    files: Object,
    ancestors: Object,
    quota: Object,
    teamMembers: Array,
    customers: Array,
    filePermissions: Array,
    allFilePermissions: Array,
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const toast = useToast()

const newFolderForm = useForm({
    parent_id: undefined,
    name: undefined
})

const fileUploadForm = useForm({
    files: [],
    relative_paths: [],
    parent_id: undefined,
})

const filesDeleteForm = useForm({
    parent_id: undefined,
    all: undefined,
    ids: []
})

const selected = ref({})
const allSelected = ref(false)

const deleteFilesDialog = ref(false)
const deleteFilesDialogLoading = ref(false)
const newFolderDialog = ref(false)
const filePermissionsDialog = ref(false)
const uploadFileInput = ref()
const uploadFolderInput = ref()
const dragOver = ref(false)
const showErrorMessageDialog = ref(false)
const loadMoreIntersect = ref(null)
const allFiles = ref({
    data: props.files.data,
    next: props.files.links.next
})

const selectedFiles = computed(() => {
    return Object.keys(selected.value)
        .filter(selectedFileId => selected.value[selectedFileId])
        .map(fileId => {
            return allFiles.value.data.find(file => file.id === fileId)
        })
})

const filePermissionForm = useForm({
    team_members: {
        read: [],
        write: []
    },
    customers: {
        read: [],
        write: []
    },
    recursive: false
})

const breadcrumbs = computed(() => {
    return [
        {
            icon: 'mdi-home-outline',
            title: 'Home',
            href: route(route().current(), {folder: null})
        },
        ...props.ancestors.data.filter(ancestor => ancestor.parent_id).map(ancestor => {
            return {title: ancestor.name, href: route(route().current(), {folder: ancestor.path})}
        })
    ]
})

const currentFolderPermission = computed(() => {
    return props.filePermissions
            .find(permission => permission.file_id === props.folder.id)
        ?? props.filePermissions
            .find(permission => props.ancestors
                .data
                .map(ancestor => ancestor.id)
                .includes(permission.file_id)
            )
})

const selectedFilesContainFolder = computed(() => selectedFiles.value.filter(file => file.is_folder).length > 0)

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {title: 'Dateien'})

onMounted(() => {
    const observer = new IntersectionObserver((entries) => entries.forEach(entry => entry.isIntersecting && loadMore()), {
        rootMargin: '-250px 0px 0px 0px',
    })

    observer.observe(loadMoreIntersect.value)
})

function userCan(permission) {
    if (isAdministrator(user.value)) {
        return true
    } else if (currentFolderPermission.value?.[permission] && currentFolderPermission.value.recursive) {
        return true
    } else if (currentFolderPermission.value?.[permission] && !selectedFilesContainFolder.value) {
        return true
    } else {
        return false
    }
}

function getFilePermissions(fileId, type) {
    return props.allFilePermissions
        .filter(permission => permission.file_id === fileId && permission.model_type === `App\\Models\\${type}`)
}

function updateAllFiles() {
    allFiles.value.data = props.files.data
    allFiles.value.next = props.files.links.next
}

function download() {
    if (!allSelected.value && Object.keys(selected.value).length === 0) return

    const p = new URLSearchParams();
    if (props.folder?.id) {
        p.append('parent_id', props.folder?.id);
    }

    if (props.all) {
        p.append('all', props.all ? '1' : '0');
    } else {
        for (let id of Object.keys(selected.value).filter(id => selected.value[id])) {
            p.append('ids[]', id)
        }
    }

    openInNewTab(route('files.file.download') + '?' + p.toString())

    selected.value = {}
    allSelected.value = false
}

function loadMore() {
    if (allFiles.value.next === null) return

    axios.get(allFiles.value.next)
        .then(res => {
            allFiles.value.data = [...allFiles.value.data, ...res.data.data]
            allFiles.value.next = res.data.links.next
        })
}

function createNewFolder() {
    newFolderForm.parent_id = props.folder.id
    newFolderForm.post(route('files.folder.create'), {
        preserveScroll: true,
        onSuccess: () => {
            newFolderDialog.value = false
            newFolderFormReset()
            updateAllFiles()
        }
    })
}

function newFolderFormReset() {
    newFolderForm.reset()
    newFolderForm.clearErrors()
}

function openFolder(file) {
    if (!file.is_folder) return

    router.visit(route(route().current(), {folder: file.path}))
}

function uploadFiles(files) {
    fileUploadForm.parent_id = props.folder.id
    fileUploadForm.files = files
    fileUploadForm.relative_paths = [...files].map(file => file.webkitRelativePath)

    fileUploadForm.post(route('files.file.store'), {
        onError: () => {
            showErrorMessageDialog.value = true
        },
        onSuccess: () => {
            toast.success(`Hochladen erfolgreich!\n${fileUploadForm.files.length} Dateien wurden erfolgreich hochgeladen.`)

            fileUploadForm.clearErrors()
            fileUploadForm.reset()
            updateAllFiles()
        }
    })
}

function deleteFiles() {
    deleteFilesDialogLoading.value = true

    filesDeleteForm.parent_id = props.folder.id
    if (allSelected.value) {
        filesDeleteForm.all = true
    } else {
        filesDeleteForm.ids = Object.keys(selected.value).filter(id => selected.value[id])
    }

    filesDeleteForm.delete(route('files.file.delete'), {
        preserveState: false,
        onSuccess: () => {
            deleteFilesDialog.value = false
            selected.value = {}
            allSelected.value = false

            toast.success('Löschen erfolgreich!\nDie ausgewählten Dateien wurden erfolgreich gelöscht.')
        },
        onError: () => {
            toast.error('Löschen fehlgeschlagen!\nDie ausgewählten Dateien konnten nicht gelöscht werden. Bitte versuchen Sie es später erneut.')
        }
    })
}

function onDragOver() {
    if (!userCan('write')) return

    dragOver.value = true
}

function onDragLeave() {
    if (!userCan('write')) return

    dragOver.value = false
}

function handleDrop(event) {
    if (!userCan('write')) return

    dragOver.value = false
    const files = event.dataTransfer.files
    if (!files.length) return

    uploadFiles(files)
}

function prepareAndOpenFilePermissionsDialog() {
    let userPermissions = getFilePermissions(selectedFiles.value[0].id, 'User')
    userPermissions.forEach(userPermission => {
        if (userPermission.read) filePermissionForm.team_members.read.push(userPermission.user.id)
        if (userPermission.write) filePermissionForm.team_members.write.push(userPermission.user.id)
    })

    let customerPermissions = getFilePermissions(selectedFiles.value[0].id, 'Customer')
    customerPermissions.forEach(customerPermission => {
        if (customerPermission.read) filePermissionForm.customers.read.push(customerPermission.customer.id)
        if (customerPermission.write) filePermissionForm.customers.write.push(customerPermission.customer.id)
    })

    filePermissionForm.recursive = userPermissions[0]?.recursive ?? customerPermissions[0]?.recursive ?? false

    filePermissionsDialog.value = true
}

function saveFilePermissions() {
    filePermissionForm.put(route('files.file.update-permissions', {file: selectedFiles.value[0].id}), {
        onSuccess: () => {
            filePermissionsDialog.value = false
            updateAllFiles()
            filePermissionForm.reset()
        }
    })
}
</script>

<template>
    <Head title="Dateien"/>

    <v-container
        :fluid="true"
        class="h-100 d-flex flex-grow-1 flex-column flex-nowrap pa-0"
    >
        <v-container
            class="d-flex flex-grow-1 flex-column flex-nowrap pa-0"
            style="max-width: 1200px"
        >
            <v-card
                :style="{border: dragOver ? '5px solid #FFE000' : 'none'}"
                class="d-flex flex-column flex-grow-1"
                @drop.prevent="handleDrop"
                @dragover.prevent="onDragOver"
                @dragleave.prevent="onDragLeave"
            >
                <template
                    v-if="dragOver"
                    class="d-flex flex-grow-1 border-primary"
                >
                    <div class="d-flex flex-grow-1 flex-column align-center justify-center">
                        <h1>Drop it like it's hot!</h1>
                        <span>Lade Dateien und Order über Drag-and-drop hoch.</span>
                    </div>
                </template>

                <template v-else>
                    <v-container
                        :fluid="true"
                        class="h-100 d-flex flex-column flex-nowrap"
                    >
                        <v-row class="border-b align-center flex-grow-0">
                            <v-col class="d-flex align-center ">
                                <div class="d-flex flex-grow-1 w-0" style="overflow-x: scroll; scrollbar-width: none;">
                                    <v-breadcrumbs
                                        style="white-space: nowrap;"
                                        :items="breadcrumbs"
                                    >
                                        <template #item="{item, index}">
                                            <li
                                                :class="{'v-breadcrumbs-item--disabled': index === ancestors.data.length - 1}"
                                                class="v-breadcrumbs-item"
                                            >
                                                <Link
                                                    style="user-select: none; -webkit-user-drag: none;"
                                                    :href="item.href || ''"
                                                    class="d-flex"
                                                >
                                                    <v-icon
                                                        v-if="item.icon"
                                                        :icon="item.icon"
                                                        size="22"
                                                    />
                                                    {{ item.title }}
                                                </Link>
                                            </li>
                                        </template>
                                        <template #divider>
                                            <v-icon icon="mdi-chevron-right"/>
                                        </template>
                                    </v-breadcrumbs>
                                </div>

                                <div
                                    v-if="allSelected || Object.values(selected).includes(true)"
                                    class="d-flex ga-2"
                                >
                                    <v-btn
                                        v-if="userCan('read')"
                                        prepend-icon="mdi-cloud-arrow-down-outline"
                                        text="Download"
                                        variant="tonal"
                                        @click="download"
                                    />

                                    <v-btn
                                        v-if="userCan('write')"
                                        color="red"
                                        prepend-icon="mdi-trash-can-outline"
                                        text="Löschen"
                                        @click="deleteFilesDialog = true"
                                    />
                                </div>

                                <div
                                    v-else-if="userCan('write')"
                                    class="flex-shrink-0"
                                >
                                    <v-tooltip
                                        text="Datei hochladen"
                                        location="bottom"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-btn
                                                icon="mdi-file-upload-outline"
                                                variant="text"
                                                v-bind="props"
                                                @click="uploadFileInput.click()"
                                            />
                                        </template>
                                    </v-tooltip>

                                    <input
                                        ref="uploadFileInput"
                                        hidden
                                        multiple
                                        type="file"
                                        @change="uploadFiles($event.target.files)"
                                    >

                                    <v-tooltip
                                        text="Ordner hochladen"
                                        location="bottom"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-btn
                                                icon="mdi-folder-arrow-up-outline"
                                                variant="text"
                                                v-bind="props"
                                                @click="uploadFolderInput.click()"
                                            />
                                        </template>
                                    </v-tooltip>

                                    <input
                                        ref="uploadFolderInput"
                                        type="file"
                                        directory
                                        webkitdirectory
                                        multiple
                                        hidden
                                        @change="uploadFiles($event.target.files)"
                                    >

                                    <v-tooltip
                                        text="Ordner hinzufügen"
                                        location="bottom"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-btn
                                                icon="mdi-folder-plus-outline"
                                                variant="text"
                                                v-bind="props"
                                                @click="newFolderDialog = true"
                                            />
                                        </template>
                                    </v-tooltip>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row
                            class="h-0"
                            style="overflow: auto"
                        >
                            <v-col
                                :cols="selectedFiles.length > 0 ? 8 : 12"
                            >
                                <v-row
                                    v-if="!allFiles.data?.length"
                                    class="h-100"
                                >
                                    <v-col class="d-flex flex-column flex-grow-1 align-center justify-center ga-3">
                                        <h1>Noch keine Dateien 😅</h1>
                                        <img
                                            :src="`/img/files/no_files.svg`"
                                            alt=""
                                            style="height: 300px"
                                        >
                                        <h3 v-if="userCan('write')">Drag & Drop Dateien, um diese hochzuladen.</h3>
                                    </v-col>
                                </v-row>

                                <v-table
                                    v-if="allFiles.data?.length"
                                    :hover="true"
                                    class="w-100 bg-transparent"
                                    style="user-select: none"
                                >
                                    <thead>
                                    <tr>
                                        <th style="width: 30px; padding-right: 30px">
                                            <v-checkbox
                                                v-model="allSelected"
                                                :hide-details="true"
                                                :indeterminate="!allSelected && Object.values(selected).includes(true)"
                                                :model-value="allSelected"
                                                density="compact"
                                                @update:model-value="(value) => allFiles.data.forEach(file => selected[file.id] = value)"
                                            />
                                        </th>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Hinzugefügt von
                                        </th>
                                        <th>
                                            Letzte Änderung
                                        </th>
                                        <th>
                                            Größe
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr
                                        v-for="file in allFiles.data"
                                        :key="file.id"
                                        :style="{'background': (selected[file.id] || allSelected) ? '#ffe00040' : 'transparent'}"
                                        class="cursor-pointer"
                                        @click="selected = {[file.id]: true}"
                                        @dblclick="openFolder(file)"
                                    >
                                        <td>
                                            <v-checkbox
                                                :hide-details="true"
                                                :model-value="selected[file.id] || allSelected"
                                                density="compact"
                                                @click.stop
                                                @update:model-value="(value) => {
                                                    if (!value) allSelected = false
                                                    selected[file.id] = value
                                                }"
                                            />
                                        </td>
                                        <td class="text-no-wrap">
                                            <div class="d-flex ga-2 file-name">
                                                <FileIcon
                                                    :file="file"
                                                />
                                                <span>{{ file.name }}</span>
                                            </div>
                                        </td>
                                        <td class="text-no-wrap">{{ file.added_by }}</td>
                                        <td class="text-no-wrap">{{ file.updated_at }}</td>
                                        <td class="text-no-wrap">{{ file.is_folder ? '' : file.size }}</td>
                                    </tr>
                                    </tbody>
                                </v-table>
                                <div ref="loadMoreIntersect"/>
                            </v-col>

                            <v-col
                                v-if="selectedFiles.length > 0"
                                cols="4"
                            >
                                <v-card
                                    color="grey-lighten-5"
                                    class="d-flex flex-column px-5 ga-3"
                                    border
                                >
                                    <div class="d-flex align-center">
                                        <div class="d-flex align-center">
                                            <FileIcon
                                                v-if="selectedFiles.length === 1"
                                                :file="selectedFiles[0]"
                                                class="me-2"
                                            />
                                            <span>{{
                                                    selectedFiles.length === 1 ? selectedFiles[0].name : `${selectedFiles.length} Objekte ausgewählt`
                                                }}</span>
                                        </div>
                                        <v-spacer/>
                                        <v-btn
                                            icon="mdi-close"
                                            variant="text"
                                            @click="selected = {}"
                                        />
                                    </div>

                                    <div class="d-flex flex-column">
                                        <span class="text-caption">Name</span>
                                        <span>{{ selectedFiles.length === 1 ? selectedFiles[0].name : '--' }}</span>
                                    </div>

                                    <div class="d-flex flex-column">
                                        <span class="text-caption">Größe</span>
                                        <span>{{ selectedFiles.length === 1 ? selectedFiles[0].size : '--' }}</span>
                                    </div>

                                    <div class="d-flex flex-column">
                                        <span class="text-caption">Typ</span>
                                        <span>{{ selectedFiles.length === 1 ? selectedFiles[0].mime : '--' }}</span>
                                    </div>

                                    <div class="d-flex flex-column">
                                        <span class="text-caption">Erstellt</span>
                                        <span>{{
                                                selectedFiles.length === 1 ? selectedFiles[0].created_at : '--'
                                            }}</span>
                                    </div>

                                    <div class="d-flex flex-column">
                                        <span class="text-caption">Letzte Änderung</span>
                                        <span>{{
                                                selectedFiles.length === 1 ? selectedFiles[0].updated_at : '--'
                                            }}</span>
                                    </div>

                                    <div
                                        v-if="isAdministrator(user) && selectedFiles.length === 1"
                                        class="d-flex flex-column ga-3"
                                    >
                                        <hr>

                                        <div class="d-flex flex-column ga-2">
                                            <div class="d-flex flex-column">
                                                <span class="text-caption">Berechtigungen</span>
                                                <span>{{ getFilePermissions(selectedFiles[0].id, 'User').length }} Team-Mitglieder und {{
                                                        getFilePermissions(selectedFiles[0].id, 'Customer').length
                                                    }} Kunden</span>
                                            </div>
                                            <div>
                                                <v-btn
                                                    color="primary"
                                                    text="ändern"
                                                    variant="outlined"
                                                    @click="prepareAndOpenFilePermissionsDialog"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </v-card>

            <v-progress-linear
                :model-value="quota.usage / quota.max * 100"
                color="primary"
                height="25"
                rounded
                class="my-3"
            >
                <template v-slot:default="{ value }">
                    <span class="text-caption">
                        {{ quota.usage_string }}&nbsp;von&nbsp;{{ quota.max_string }}&nbsp;verwendet
                    </span>
                </template>
            </v-progress-linear>

            <!-- New Folder Dialog -->
            <v-dialog
                v-model="newFolderDialog"
                width="450"
            >
                <v-form
                    @submit.prevent="createNewFolder"
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Neuer Ordner</span>
                        </v-card-title>
                        <v-card-text>
                            <v-text-field
                                v-model="newFolderForm.name"
                                :error-messages="newFolderForm.errors.name"
                                label="Name"
                                variant="outlined"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn
                                :disabled="newFolderForm.processing"
                                @click="newFolderDialog = false; newFolderFormReset"
                            >
                                Abbrechen
                            </v-btn>
                            <v-btn
                                :disabled="newFolderForm.processing"
                                :loading="newFolderForm.processing"
                                color="primary"
                                type="submit"
                            >
                                Erstellen
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>

            <!-- File Permissions Dialog -->
            <v-dialog
                v-model="filePermissionsDialog"
                width="450"
                persistent
            >
                <v-form
                    @submit.prevent="saveFilePermissions"
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Berechtigungen ändern</span>
                        </v-card-title>
                        <v-card-text>
                            <div class="d-flex flex-column ga-2">
                                <v-label>Berechtigungen für "{{ selectedFiles[0].name }}"</v-label>

                                <span>Lesen</span>
                                <v-autocomplete
                                    v-model="filePermissionForm.team_members.read"
                                    :items="teamMembers?.map(member => { return { title: `${member.firstname} ${member.lastname}`, value: member.id } })"
                                    label="Team-Mitglieder"
                                    variant="outlined"
                                    chips
                                    multiple
                                    hide-details="auto"
                                />

                                <v-autocomplete
                                    v-model="filePermissionForm.customers.read"
                                    :items="customers?.map(customer => { return { title: customer.name, value: customer.id } })"
                                    label="Kunden"
                                    variant="outlined"
                                    chips
                                    multiple
                                    hide-details="auto"
                                />

                                <v-spacer/>

                                <span>Schreiben</span>
                                <v-autocomplete
                                    v-model="filePermissionForm.team_members.write"
                                    :items="teamMembers?.map(member => { return { title: `${member.firstname} ${member.lastname}`, value: member.id } })"
                                    label="Team-Mitglieder"
                                    variant="outlined"
                                    chips
                                    multiple
                                    hide-details="auto"
                                />

                                <v-autocomplete
                                    v-model="filePermissionForm.customers.write"
                                    :items="customers?.map(customer => { return { title: customer.name, value: customer.id } })"
                                    label="Kunden"
                                    variant="outlined"
                                    chips
                                    multiple
                                    hide-details="auto"
                                />

                                <v-checkbox
                                    v-model="filePermissionForm.recursive"
                                    label="Berechtigungen vererben"
                                    hide-details="auto"
                                />
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn
                                :disabled="filePermissionForm.processing"
                                @click="filePermissionsDialog = false; filePermissionForm.reset()"
                            >
                                Abbrechen
                            </v-btn>
                            <v-btn
                                :disabled="filePermissionForm.processing"
                                :loading="filePermissionForm.processing"
                                color="primary"
                                type="submit"
                            >
                                Speichern
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
        </v-container>

        <DeleteFilesDialog
            :is-loading="deleteFilesDialogLoading"
            :is-open="deleteFilesDialog"
            @close="deleteFilesDialog = false"
            @confirm="deleteFiles"
        />

        <MessageModal
            :is-open="showErrorMessageDialog"
            :message="Object.values(fileUploadForm.errors).join('\n')"
            title="Fehler beim Datei-Upload"
            @close="showErrorMessageDialog = false; fileUploadForm.clearErrors()"
        />

        <FormProgress
            :form="fileUploadForm"
            title="Dateien hochladen"
        />
    </v-container>
</template>

<style scoped>

.file-name {
    overflow: hidden;
    text-overflow: ellipsis;
}

</style>
