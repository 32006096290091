<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {useForm} from "@inertiajs/vue3";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCcAmex, faCcMastercard, faCcVisa, faPaypal} from "@fortawesome/free-brands-svg-icons";
import {faBuildingColumns} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {loadStripe} from "@stripe/stripe-js";
import PriceCarousel from "@/Pages/Console/Onboarding/PriceCarousel.vue";
import {getProductDiscountPrice} from "../helper.js";
import {useToast} from "vue-toastification";

library.add(faCcVisa, faCcAmex, faCcMastercard, faBuildingColumns, faPaypal)

const props = defineProps({
    isOpen: Boolean,
    products: Array,
    paymentMethods: Array,
    defaultPaymentMethod: Object,
    company: Object,
    stripePublishableKey: String
})

const toast = useToast()

const emit = defineEmits([
    'close',
])

const yearlyMonthlySwitch = ref('monthly')
const newSubscriptionForm = useForm({
    name: props.products[0].name,
    price_id: props.products[0].prices[yearlyMonthlySwitch.value].id,
    amount: props.products[0].prices[yearlyMonthlySwitch.value].unit_amount
})
watch(yearlyMonthlySwitch, () => newSubscriptionForm.reset())
const paymentMethodType = ref(props.defaultPaymentMethod?.id ?? 'new')

const stripe = ref()
const elements = ref()
const paymentElement = ref()
const paymentElementCompleted = ref(false)
const selectedPaymentMethod = ref()
const createSubscriptionLoading = ref(false)

const selectedProduct = ref(props.products[0])
watch(selectedProduct, updatedProduct => {
    Object.assign(newSubscriptionForm, {
        name: updatedProduct.name,
        price_id: updatedProduct.prices[yearlyMonthlySwitch.value].id,
        amount: updatedProduct.prices[yearlyMonthlySwitch.value].unit_amount
    })
    loadPaymentElements()
})

const paymentStepDisabled = computed(() => selectedProduct?.percent_off === 100.0 || selectedProduct?.amount_off >= selectedProduct?.prices[yearlyMonthlySwitch.value].unit_amount)

onMounted(async () => {
    stripe.value = await loadStripe(props.stripePublishableKey)
    if (paymentMethodType.value === 'new') {
        await nextTick()
        await loadPaymentElements()
    }
})

async function loadPaymentElements() {
    const options = {
        mode: 'subscription',
        amount: newSubscriptionForm.amount,
        currency: 'eur',
        setupFutureUsage: 'off_session',
        paymentMethodTypes: ['card', 'sepa_debit', 'paypal'],
        appearance: {
            theme: 'flat',
            labels: 'floating',
            variables: {
                colorPrimary: '#ffe000',
                colorDanger: '#df1b41',
                fontFamily: 'Roboto, sans-serif',
                borderRadius: '4px',
            }
        }
    }
    elements.value = stripe.value.elements(options)
    paymentElement.value = elements.value.create('payment')
    paymentElement.value.mount('#payment-element')

    paymentElement.value.on('change', event => {
        paymentElementCompleted.value = event.complete
        selectedPaymentMethod.value = event.value.type
    })
}

async function createSubscription() {
    createSubscriptionLoading.value = true

    if (paymentMethodType.value === 'new' && elements.value) {
        const {error: submitError} = await elements.value.submit();
        if (submitError) {
            console.log(submitError)
            createSubscriptionLoading.value = false
            return
        }
    }

    axios.post(
        route('onboarding.create-subscription'),
        {
            'stripe_price_id': newSubscriptionForm.price_id,
            'payment_method': paymentMethodType.value === 'new' || paymentStepDisabled.value ? null : paymentMethodType.value
        }
    ).then(async res => {
        if (res.data.client_secret) {
            const {error} = await stripe.value.confirmPayment({
                elements: paymentMethodType.value === 'new' ? elements.value : undefined,
                clientSecret: res.data.client_secret,
                confirmParams: {
                    return_url: window.location.href,
                }
            })

            if (error) {
                toast.error(`Es ist ein Fehler aufgetreten!\n${error.message}`)
            }
        }

        if (res.data.pending_setup_intent && res.data.setup_client_secret) {
            await stripe.value.confirmSetup({
                elements: paymentMethodType.value === 'new' ? elements.value : undefined,
                clientSecret: res.data.setup_client_secret,
                confirmParams: {
                    return_url: window.location.href,
                }
            })
        }

        if (res.data === 'OK') {
            window.location.href = route('index')
        }

        createSubscriptionLoading.value = false
    })
}
</script>

<template>
    <v-dialog
        :model-value="isOpen"
        width="auto"
    >
        <v-card>
            <v-card-text>
                <div class="d-flex flex-column ga-3">
                    <div>
                        <h3>Welches Abo passt zu dir?</h3>
                    </div>

                    <PriceCarousel
                        v-model="selectedProduct"
                        :products="products"
                        :yearly-monthly-switch="yearlyMonthlySwitch"
                        hide-action-buttons
                    />

                    <div v-if="!paymentStepDisabled">
                        <div>
                            <h3>Zahlungsmethode</h3>
                        </div>

                        <div>
                            <v-radio-group
                                v-if="paymentMethods.length"
                                v-model="paymentMethodType"
                                :disabled="createSubscriptionLoading"
                                @change="loadPaymentElements"
                            >
                                <v-radio
                                    v-for="paymentMethod in paymentMethods"
                                    :key="paymentMethod.id"
                                    :value="paymentMethod.id"
                                >
                                    <template #label>
                                        <div>
                                            <div
                                                v-if="paymentMethod.type === 'paypal'"
                                                class="d-flex ga-3 align-center"
                                            >
                                                <font-awesome-icon
                                                    :icon="['fab', 'paypal']"
                                                    size="xl"
                                                />

                                                <div>{{ paymentMethod.paypal.payer_email }}</div>
                                            </div>

                                            <div
                                                v-else-if="paymentMethod.type === 'sepa_debit'"
                                                class="d-flex ga-3 align-center"
                                            >
                                                <font-awesome-icon
                                                    :icon="['fas', 'building-columns']"
                                                    size="xl"
                                                />

                                                <div>{{ paymentMethod.sepa_debit.last4 }}</div>
                                            </div>

                                            <div
                                                v-else-if="paymentMethod.type === 'card'"
                                                class="d-flex ga-3 align-center"
                                            >
                                                <font-awesome-icon
                                                    :icon="['fab', 'cc-' + paymentMethod.card.brand]"
                                                    size="xl"
                                                />

                                                <div>***{{ paymentMethod.card.last4 }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </v-radio>

                                <v-radio
                                    label="Zahlungsmethode hinzufügen"
                                    value="new"
                                />
                            </v-radio-group>

                            <div
                                v-show="paymentMethodType === 'new'"
                                id="payment-element"
                                class="my-3"
                                style="min-height: 215px"
                            />
                        </div>
                    </div>

                    <div>
                        Mit dem Klick auf "{{ newSubscriptionForm.name }} abonnieren" akzeptiere ich die aktuell
                        geltenden
                        Nutzungsbedingungen und Datenschutzerklärung.
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    :disabled="createSubscriptionLoading"
                    @click="$emit('close')"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    color="primary"
                    :loading="createSubscriptionLoading"
                    @click="createSubscription"
                >
                    {{ newSubscriptionForm.name }} für {{
                        getProductDiscountPrice(selectedProduct, yearlyMonthlySwitch).toLocaleString()
                    }}&nbsp;€ abonnieren
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>

.product {
    border: 5px solid #ccc;
    border-radius: 20px;
    padding: 20px;
}

.product.selected {
    border: 5px solid rgba(var(--v-theme-primary));
}

.product:hover:not(.selected) {
    border: 5px solid rgba(var(--v-theme-primary), 0.5);
}

.product.disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
    cursor: not-allowed;
}

</style>
