<script setup>
import {computed, ref} from "vue";
import Default from "@/Layouts/Default.vue";
import Wrapper from "@/Pages/Console/Settings/Wrapper.vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import {validationRules} from "@/helper.js";
import {useToast} from "vue-toastification";

defineOptions({layout: [Default, Wrapper]})
const props = defineProps({
    domain: Object
})

const toast = useToast()

const page = usePage()
const user = computed(() => page.props.auth.user)
const branding = computed(() => page.props.branding)

const form = useForm({
    color_primary: branding.value.color_primary,
    color_on_primary: branding.value.color_on_primary,
    color_background: branding.value.color_background,
})

const domainForm = useForm({
    domain: branding.value.domain
})

const addDomainDialog = ref(false)
const addDomainForm = ref()
const dnsRecordsDialog = ref(false)
const checkDNSEntriesLoading = ref(false)

function saveBrandingData() {
    form.put(route('settings.branding.update'), {
        onSuccess: () => window.location.reload()
    })
}

async function addDomain() {
    const {valid} = await addDomainForm.value.validate()
    if (!valid) return

    domainForm.post(route('settings.branding.domain.store'), {
        onSuccess: () => {
            addDomainDialog.value = false
            domainForm.reset()

            router.reload({
                only: ['domain'],
                onSuccess: () => {
                    if (props.domain) dnsRecordsDialog.value = true
                }
            })
        }
    })
}

function copyToClipboard(event) {
    const spanText = event.target.innerText
    window.navigator.clipboard?.writeText(spanText)

    toast.success(`"${spanText}" in die Zwischenablage kopiert.`)
}

function checkDNSEntries() {
    checkDNSEntriesLoading.value = true

    router.patch(route('settings.branding.domain.check-dns', {domain: props.domain.id}), undefined, {
        onFinish: () => {
            checkDNSEntriesLoading.value = false

            if (props.domain?.state === 'authorized') {
                dnsRecordsDialog.value = false
            }
        }
    })
}

function getDNSStatusIconName(status) {
    switch (status) {
        case 'active':
            return {icon: 'mdi-check-circle-outline', color: 'green'}

        default:
            return {icon: 'mdi-help-circle-outline', color: 'orange'}
    }
}
</script>

<template>
    <v-row class="flex-grow-0">
        <v-col>
            <v-card class="pa-5 d-flex flex-column ga-3">
                <div>
                    <h3>Farben</h3>

                    <span class="text-caption font-weight-light">Setze deine CI-Farben</span>
                </div>

                <div class="d-flex ga-3 align-center">
                    <input
                        v-model="form.color_primary"
                        type="color"
                        style="height: 50px; width: 50px"
                    />

                    <span class="font-weight-bold">Primär</span>
                </div>

                <div class="d-flex ga-3 align-center">
                    <input
                        v-model="form.color_on_primary"
                        type="color"
                        style="height: 50px; width: 50px"
                    />

                    <span class="font-weight-bold">Text auf Primär</span>
                </div>

                <div class="d-flex ga-3 align-center">
                    <input
                        v-model="form.color_background"
                        :disabled="!form.color_background"
                        :style="{ opacity: !form.color_background ? 0.5 : 1.0 }"
                        type="color"
                        style="height: 50px; width: 50px"
                    />

                    <v-switch
                        color="primary"
                        :model-value="!!form.color_background"
                        @update:model-value="value => !value ? form.color_background = null : form.color_background = branding.color_background ?? branding.color_primary"
                        hide-details
                    />

                    <span class="font-weight-bold">Login Hintergrund</span>
                </div>
            </v-card>
        </v-col>

        <v-col>
            <v-card class="pa-5 d-flex flex-column ga-3">
                <div>
                    <h3>Domain</h3>

                    <span class="text-caption font-weight-light">Deine eigene App-Domain</span>
                </div>

                <div class="d-flex flex-column ga-3">
                    <div>
                        <h4>Aktuelle Domain</h4>
                        <div class="d-flex ga-1">
                            <v-icon
                                v-if="domain && (domain?.dns_cname_status !== 'active' || domain?.state !== 'authorized')"
                                icon="mdi-help-circle-outline"
                                color="grey"
                            />

                            <v-icon
                                v-if="!domain || (domain?.dns_cname_status === 'active' && domain?.state === 'authorized')"
                                icon="mdi-check-circle-outline"
                                color="green"
                            />
                            <span>{{ domain?.id ?? 'app.joiny.io' }}</span>
                        </div>
                    </div>

                    <div class="d-flex ga-3">
                        <v-btn
                            v-if="domain && (domain?.dns_cname_status !== 'active' || domain?.state !== 'authorized')"
                            text="DNS Einträge"
                            variant="outlined"
                            @click="dnsRecordsDialog = true"
                        />

                        <v-tooltip
                            text="Domain kann nur unter app.joiny.io geändert werden."
                            location="bottom"
                            :disabled="window.location.hostname === 'app.joiny.io'"
                        >
                            <template #activator="{ props }">
                                <div v-bind="props">
                                    <v-btn
                                        text="Domain ändern"
                                        color="primary"
                                        variant="outlined"
                                        :disabled="window.location.hostname !== 'app.joiny.io'"
                                        @click="addDomainDialog = true"
                                    />
                                </div>
                            </template>
                        </v-tooltip>
                    </div>
                </div>
            </v-card>
        </v-col>
    </v-row>

    <v-row class="flex-grow-0">
        <v-col class="text-end">
            <v-btn
                prepend-icon="mdi-content-save-outline"
                :loading="form.processing"
                :disabled="!form.isDirty"
                text="Speichern"
                color="primary"
                type="submit"
                @click="saveBrandingData"
            />
        </v-col>
    </v-row>

    <!-- Add Domain Dialog -->
    <v-dialog
        v-model="addDomainDialog"
        width="450"
        persistent
    >
        <v-form
            ref="addDomainForm"
            @submit.prevent="addDomain"
            validate-on="none"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Eigene Domain verwenden</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="domainForm.domain"
                        :error-messages="domainForm.errors.domain"
                        label="Domain"
                        variant="outlined"
                        placeholder="app.joiny.io"
                        :rules="[validationRules.required]"
                        autofocus
                        :disabled="domainForm.processing"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        :disabled="domainForm.processing"
                        @click="addDomainDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        :disabled="domainForm.processing || !domainForm.domain"
                        :loading="domainForm.processing"
                        color="primary"
                        type="submit"
                    >
                        Verwenden
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>

    <!-- DNS Records Dialog -->
    <v-dialog
        v-model="dnsRecordsDialog"
        class="d-flex dns-records-dialog"
        width="auto"
    >
        <v-card v-if="domain" class="d-flex">
            <v-card-title>
                <span class="text-h5">Eigene Domain verwenden</span>
            </v-card-title>
            <v-card-text>
                <div class="d-flex flex-column ga-3">
                    <span>Um eine eigene Domain zu verwenden, muss ein DNS-Eintrag bei dem entsprechenden Domain-Hoster
                        angelegt
                        werden.</span>

                    <table>
                        <tbody>
                        <tr class="flex-nowrap">
                            <td>Typ</td>
                            <td>Name</td>
                            <td>Wert</td>
                            <td>Status</td>
                        </tr>

                        <tr class="overflow-auto">
                            <td>CNAME</td>
                            <td
                                class="cursor-pointer"
                                @click="copyToClipboard($event)"
                            >
                                <span>{{ domain.id }}</span>
                            </td>
                            <td
                                class="cursor-pointer"
                                @click="copyToClipboard($event)"
                            >
                                <span>app.joiny.io</span>
                            </td>
                            <td>
                                <v-icon
                                    v-bind="getDNSStatusIconName(domain.dns_cname_status)"
                                />
                            </td>
                        </tr>

                        <tr v-if="domain.dns_cname_status === 'active'">
                            <td colspan="3">
                                <span>SSL-Zertifikat</span>
                            </td>
                            <td>
                                <div>
                                    <v-icon
                                        v-if="domain.state === 'authorized'"
                                        icon="mdi-check-circle-outline"
                                        color="green"
                                    />

                                    <v-icon
                                        v-if="domain.state === 'failed'"
                                        icon="mdi-close-circle-outline"
                                        color="red"
                                    />

                                    <div
                                        v-if="domain.state === 'authorizing'"
                                        class="d-flex ga-3"
                                    >
                                        <v-progress-circular indeterminate size="small" width="3"/>
                                        <span>wird erstellt</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <span class="text-caption">Die Validierung der DNS-Einträge sowie die anschließende Erstellung des
                        SSL-Zertifikates kann bis zu 24 Stunden dauern.</span>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn
                    :disabled="checkDNSEntriesLoading"
                    @click="dnsRecordsDialog = false"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    text="Aktualisieren"
                    color="primary"
                    :loading="checkDNSEntriesLoading"
                    @click="checkDNSEntries"
                />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>

.dns-records-dialog:deep(input) {
    outline: none;
    padding: 0;
}

.dns-records-dialog:deep(td) {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.dns-records-dialog:deep(table) {
    border-collapse: collapse;
}

</style>
