<script setup>
import {getProductBasePrice, getProductDiscountPrice} from "../../../helper.js";

const model = defineModel({
    required: true
})

const props = defineProps({
    products: Array,
    yearlyMonthlySwitch: String,
    hideActionButtons: Boolean,
})

defineEmits([
    'submit'
])
</script>

<template>
    <div class="d-flex flex-column ga-3 mb-3">
        <v-carousel
            v-model="model"
            class="align-self-center"
            height="auto"
            hide-delimiters
        >
            <template #next="{props}">
                <v-btn
                    v-bind="props"
                    color="primary"
                />
            </template>

            <template #prev="{props}">
                <v-btn
                    v-bind="props"
                    color="primary"
                />
            </template>

            <v-carousel-item
                v-for="product in products"
                :key="product.id"
                :value="product"
            >
                <v-card class="d-flex flex-column ga-3">
                    <div
                        class="d-flex flex-column align-items-center text-center"
                    >
                        <h4>{{ product.name }}</h4>

                        <div
                            v-if="product.percent_off || product.amount_off"
                            class="strike-price align-self-center mt-4"
                        >
                            <span style="font-size: 25px;">
                                {{
                                    getProductBasePrice(product, yearlyMonthlySwitch).toLocaleString()
                                }} €<span
                                style="font-size: 12px;">/ Monat</span></span>
                        </div>

                        <h4 style="font-size: 44px;">
                            {{
                                getProductDiscountPrice(product, yearlyMonthlySwitch).toLocaleString()
                            }} €<span
                            style="font-size: 12px;">/ Monat</span></h4>

                        <div class="align-self-center">
                            <ul>
                                <li class="d-flex mb-2 ga-2"
                                    v-for="feature in product.features">
                                    <v-icon
                                        icon="mdi-check"
                                    />
                                    <span>{{
                                            feature
                                        }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <v-btn
                        v-if="!hideActionButtons"
                        class="align-self-center"
                        color="primary"
                        style="width: 261px;"
                        :text="`${product.name} auswählen`"
                        @click="$emit('submit')"
                    />
                </v-card>
            </v-carousel-item>
        </v-carousel>
    </div>
    <div class="text-center text-caption">
        Alle Preise verstehen sich zzgl. MwSt.
    </div>
    <div class="text-center">
        <a
            href="https://joiny.io/preise#pricing"
            target="_blank"
        >
            Weitere Plan-Details
        </a>
    </div>
</template>

<style scoped>
.strike-price {
    background: linear-gradient(to top left,
    rgba(255, 0, 0, 0) 0%,
    rgba(255, 0, 0, 0) calc(50% - 2px),
    rgba(255, 0, 0, 1) calc(50% - 1px),
    rgba(255, 0, 0, 1) 50%,
    rgba(255, 0, 0, 1) calc(50% + 1px),
    rgba(255, 0, 0, 0) calc(50% + 2px),
    rgba(255, 0, 0, 0) 100%);
}
</style>
